.ProgressBar {
    background-color: rgba(255, 255, 255, 0.15);
    box-shadow: 0 0 0 2px rgba(0,0,0, 0.5), inset 0 1px 1px rgba(255, 255, 255, 0.2);
    border-radius: 0.4rem;
    transition: all var(--transition-time);
    width: 100%;
    height: 1.6rem;
    overflow: hidden;
}

.ProgressBarInner {
    background-color: var(--white-10);
    border-radius: 0.4rem;
    transition: all 0.2s;
    height: 100%;
}
