.ResizedImage {
    border-radius: 0.4rem;
    background-color: rgba(83,82,131, 0.1);
    box-shadow: 0 0 0 2px rgba(0,0,0, 0.5), inset 0 1px 1px rgba(255, 255, 255, 0.2);
    transition: box-shadow var(--transition-time);
    overflow: hidden;
}
.ResizedImage:active {
    background-color: rgba(83,82,131, 1);
}
.ResizedImage:hover {
    box-shadow: 0 0 0 4px rgba(0,0,0, 0.5), inset 0 1px 1px rgba(255, 255, 255, 0.2);
}

.ResizedImage > .Preview {
    height: calc(112px + 2rem);
    display: flex;
    align-items: center;
    justify-content: center;
}

.ResizedImage > .Info {
    background-color: rgba(83,82,131, 1);
    box-sizing: border-box;
    padding: 0.5rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.ResizedImage > .Info > .FileSize {
    font-weight: bold;
}
